import { atom } from 'jotai';
import { type PotionItem } from '@/apis/potion';

export const emptyPotion = {
  potionId: "",
  payId: "",
  name: "",
  describe: "",
  star: 0,
  usdt: "",
  haveCount: "",
  label: "",
};

export const isAdPlayingAtom = atom(false);
export const isEasterEggFirstTimeAtom = atom(true);
export const isCopiedEasterEggPassAtom = atom(false);
export const isHiddenNavigatorAtom = atom(false);
export const currentPotionAtom = atom<PotionItem>(emptyPotion);
export const isWalletModalOpenAtom = atom(false);
