import React from 'react';
import useBizSound from '../../../hooks/useBizSound';
import useImpactOccurred from '@/hooks/useVibration';
import { ImpactHapticFeedbackStyle } from '@telegram-apps/sdk-react';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { isAdPlayingAtom } from '@/store/runtime';
import { useAtom } from 'jotai';

export interface BaseInteractiveProps {
  audioSrc?: string;
  disabled?: boolean;
  disableAudioWhenClick?: boolean;
  disableHapticWhenClick?: boolean;
  impactHaptic?: ImpactHapticFeedbackStyle;
  onInteractiveClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onInteractiveTouchStart?: (event: React.TouchEvent<HTMLElement>) => void;
}

export const BaseInteractive: React.FC<
  BaseInteractiveProps & { children: React.ReactNode }
> = ({
  audioSrc = getPublicAsset('/audios/click.wav'),
  disabled = false,
  disableAudioWhenClick = false,
  disableHapticWhenClick = false,
  impactHaptic = 'light',
  onInteractiveClick,
  onInteractiveTouchStart,
  children,
}) => {
  const [playSound] = useBizSound(audioSrc, { interrupt: true });
  const [impactOccurred] = useImpactOccurred(impactHaptic);
  const [isAdPlaying] = useAtom(isAdPlayingAtom);

  const supportsTouch = 'ontouchstart' in document.documentElement;

  const handleInteraction = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
  ) => {
    if (isAdPlaying) {
      return;
    }
    if (disabled) {
      return;
    }
    if (!disableAudioWhenClick && audioSrc) {
      playSound();
    }
    if (!disableHapticWhenClick) {
      impactOccurred();
    }

    if (onInteractiveClick) {
      onInteractiveClick(event as React.MouseEvent<HTMLElement>);
    }

    if (onInteractiveTouchStart) {
      onInteractiveTouchStart(event as React.TouchEvent<HTMLElement>);
    }
  };

  return React.cloneElement(
    React.Children.only(children) as React.ReactElement,
    {
      ...(supportsTouch && onInteractiveTouchStart
        ? { onTouchStart: handleInteraction }
        : { onClick: handleInteraction }),
    },
  );
};
