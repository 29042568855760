import { cloneDeep } from 'lodash-es';
import {
  LOCAL_KEY_USER_INFO,
  LOCAL_KEY_USER_TOKEN,
} from '@/constants/localStorageKeys';
import { TUserInfo } from '@/types/user';

const hourMilliSeconds = 1000 * 3600;
const dayMilliSeconds = hourMilliSeconds * 24;

export function setLocalStorage(key: string, value: any, /** in ms*/ expires?) {
  if (typeof window !== 'undefined' && window.localStorage) {
    const entry = {
      value,
      expires,
    };
    try {
      window.localStorage.setItem(key, JSON.stringify(entry));
    } catch (error) {
      console.error('setLocalStorage', error);
    }
  }
}

export function removeLocalStorage(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error('removeLocalStorage', error);
  }
}

export function getLocalStorage(key, saved = false) {
  if (typeof window !== 'undefined' && window.localStorage) {
    let entry;
    try {
      entry = window.localStorage.getItem(key);
    } catch (error) {
      console.error('getFromLocalStorage', error);
      return undefined;
    }

    try {
      entry = JSON.parse(entry);
    } catch (e) {
      // Just use raw value
    }
    // save option will not delete localstorage data but return a expire tag
    if (saved) {
      let expired = false;
      if (entry && entry.expires && Date.now() > entry.expires) {
        expired = true;
      }
      const storedValue =
        entry && entry.value !== undefined ? entry.value : entry;
      return { data: storedValue, expired: expired };
    } else {
      if (entry && entry.expires && Date.now() > entry.expires) {
        removeLocalStorage(key);
        return null;
      } else {
        const storedValue =
          entry && entry.value !== undefined ? entry.value : entry;
        return storedValue;
      }
    }
  }
  return '';
}

export function getUserInfoFromLocal() {
  const localUserInfo = getLocalStorage(LOCAL_KEY_USER_INFO);
  return localUserInfo;
}

export const setLocalUserInfo = (userInfo?: Partial<TUserInfo>) => {
  if (userInfo) {
    // token will be set with http-only cookie, nomore localStorage in !WEB!
    const newUserInfo = cloneDeep(userInfo);
    setLocalStorage(LOCAL_KEY_USER_INFO, newUserInfo);
    setLocalStorage(LOCAL_KEY_USER_TOKEN, userInfo?.token);
  } else {
    removeLocalStorage(LOCAL_KEY_USER_INFO);
    removeLocalStorage(LOCAL_KEY_USER_TOKEN);
  }
};
