import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useSound as useHowlerSound } from 'use-sound';
import { enableSoundEffectsAtom } from '@/store/settings';

type SoundEffect = ReturnType<typeof useHowlerSound>[0];

export const useBizSound = (
  soundSrc: string,
  options?: Parameters<typeof useHowlerSound>[1] & { loop?: boolean },
) => {
  const [enableSoundEffects] = useAtom(enableSoundEffectsAtom);
  const [play, soundData] = useHowlerSound(soundSrc, options);

  const conditionalPlay: SoundEffect = useCallback(
    (...args: Parameters<SoundEffect>) => {
      if (enableSoundEffects) {
        play(...args);
      }
    },
    [enableSoundEffects, play],
  );

  return [conditionalPlay, soundData] as const;
};

export default useBizSound;
