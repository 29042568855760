const getDeploymentPrefix = (): string => {
  const deployEnv = process.env.NEXT_PUBLIC_DEPLOY_ENV;

  switch (deployEnv) {
    case 'development':
      return '';
    case 'staging':
      return '';
    case 'beta':
      return '';
    case 'production':
    default:
      return '';
  }
};

export const getPublicAsset = (assetPath: string): string => {
  const deploymentPrefix = getDeploymentPrefix();
  return `${deploymentPrefix}${assetPath}`;
};
