import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import './button.css';
import { cn } from '@/lib/utils';
import { getPublicAsset } from '@/utils/getPublicAsset';
import { BaseInteractive, BaseInteractiveProps } from './BaseInteractiveButton';

const buttonVariants = cva(
  'btn relative   mr-[0.5rem] mb-[0.5rem]  border-t-[2px] border-l-[2px] border-black  px-2 py-1 z-0 rounded transition-all  font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-transparent',
        outline: 'bg-transparent',
        yellow: 'bg-[#FFD33B] shadow-yellow text-black',
        dark: 'bg-[#3B392D] shadow-dark text-black',
        green: 'bg-[#C9D849] shadow-green text-black',
        gray: 'bg-[#CAC4B8] shadow-gray text-black',
      },
      size: {
        default: '',
        sm: 'px-3 text-xs',
        lg: 'px-8',
        icon: 'w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants>,
    Omit<BaseInteractiveProps, 'onInteractiveClick'> {
  asChild?: boolean;
  shadowColor?: string;
  disableHoverAndActive?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      audioSrc = getPublicAsset('/audios/click.wav'),
      disableAudioWhenClick = false,
      asChild = false,
      disableHoverAndActive = false,
      disableHapticWhenClick = false,
      shadowColor,
      impactHaptic = 'light',
      onClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const style = {
      '--shadow-color': shadowColor,
    };
    return (
      <BaseInteractive
        audioSrc={audioSrc}
        disableAudioWhenClick={disableAudioWhenClick}
        disableHapticWhenClick={disableHapticWhenClick}
        impactHaptic={impactHaptic}
        onInteractiveClick={onClick}
      >
        <Comp
          style={shadowColor&&style}
          className={cn(
            '',
            buttonVariants({ variant, size }),
            {
              'transform': !disableHoverAndActive,
            },
            className,
          )}
          ref={ref}
          {...props}
        />
      </BaseInteractive>
    );
  },
);

Button.displayName = 'Button';

export { Button, buttonVariants };
