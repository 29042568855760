import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { enableVibrationAtom } from '@/store/settings';
import {
  useHapticFeedback,
  ImpactHapticFeedbackStyle,
} from '@telegram-apps/sdk-react';

export const useImpactOccurred = (event: ImpactHapticFeedbackStyle) => {
  const [enableVibration] = useAtom(enableVibrationAtom);
  const haptic = useHapticFeedback();

  const conditionalPlay = useCallback(() => {
    if (enableVibration && event) {
      haptic.impactOccurred(event);
      haptic.notificationOccurred('success')
    }
  }, [enableVibration, event]);

  return [conditionalPlay] as const;
};

export default useImpactOccurred;
