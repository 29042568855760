import { atom } from 'jotai';
import { getLocalStorage, setLocalStorage } from '@/utils/localStoreHelper';
import { isNull } from 'lodash-es';

function atomWithLocalStorage<T>(key: string, initialValue: T) {
  const oldValue = getLocalStorage(key);
  const baseAtom = atom(!isNull(oldValue) ? oldValue : initialValue);
  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T) => {
      const nextValue =
        typeof update === 'function' ? update(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      setLocalStorage(key, nextValue);
    },
  );
  return derivedAtom;
}

export const enableSoundEffectsAtom = atomWithLocalStorage(
  'enableSoundEffects',
  true,
);
export const enableMusicAtom = atomWithLocalStorage('enableMusic', false);
export const enableVibrationAtom = atomWithLocalStorage(
  'enableVibration',
  true,
);
